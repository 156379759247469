import React, { Fragment } from 'react';

import Progres from './progres';
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ReactPaginate from 'react-paginate';
import { Modal } from "antd";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import MapPicker from 'react-google-map-picker';
import { APIKEY } from "../../helper/host";
import { Spin } from "antd";
import i18n from "i18next";
import RefreshIcon from '../../assets/icons/refresh.svg';
import PlusWhiteIcon from '../../assets/icons/plus-white.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import SearchIcon from '../../assets/icons/search.svg';
import EmptyDataImage from '../../assets/images/data-kosong.png';
import PencilIcon from '../../assets/icons/pencil.svg';
import TrashIcon from '../../assets/icons/trash.svg';

class LokasiGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shift: [],
            lokasi: [],
            divisi: [],
            karyawan: [],
            progresModal: false,
            modalAdd: false,
            totalData: 0,
            totalPerpage: 0,
            pageCount: 0,
            perPage: 0,
            radius_lokasi: 100,
            id_lokasi: 0,
            location: {
                lat: -7.865242,
                lng: 110.156070
            },
            nama_lokasi: "",
            tipe_lokasi: "pusat",
            alamat_lokasi: "",
            filter_nama: "",
            disable_add: false,
            disable_edit: false,
            loadingList: false
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }
    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(e) {
        const target = e.target.value
        const name = e.target.name

        this.setState({
            [name]: target
        })
    }

    simpanLokasi = () => {
        this.setState({
            disable_add: true,
        })

        let form = {
            nama: this.state.nama_lokasi,
            perusahaan_title: this.state.tipe_lokasi === '' ? 'pusat' : this.state.tipe_lokasi,
            perusahaan_alamat: this.state.alamat_lokasi,
            latitude: this.state.location.lat,
            longitude: this.state.location.lng,
            max_jarak: this.state.radius_lokasi
        }

        this.props.request("lokasi/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalAdd: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 0,
                                alamat_lokasi: "",
                                disable_add: false
                            }, () => {
                                this.loadData()
                                this.loadCheck()
                            })
                        } else {
                            this.setState({
                                modalAdd: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 0,
                                alamat_lokasi: ""
                            }, () => {
                                this.loadData()
                                this.loadCheck()
                            })
                        }
                    })
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    hapusLokasi() {
        let form = {
            id: this.state.id_lokasi
        }
        this.props.request("lokasi/delete-lokasi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.loadData()
                    this.loadCheck()
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning'
                    })
                }
            })
    }

    detailLokasi() {
        let form = {
            id: this.state.id_lokasi
        }
        this.props.request("lokasi/detail", form, "POST")
            .then((response) => {
                const data = response.data
                if (response.success) {
                    this.setState({
                        nama_lokasi: data.lokasi_nama,
                        radius_lokasi: data.max_jarak,
                        alamat_lokasi: data.perusahaan_alamat,
                        location: {
                            lng: data.longitude,
                            lat: data.latitude
                        },
                        tipe_lokasi: data.perusahaan_title,
                        modalEdit: true
                    })
                }
            })
    }

    editLokasi = () => {
        this.setState({
            disable_edit: true
        })
        let form = {
            id: this.state.id_lokasi,
            nama: this.state.nama_lokasi,
            perusahaan_title: this.state.tipe_lokasi === '' ? 'pusat' : this.state.tipe_lokasi,
            perusahaan_alamat: this.state.alamat_lokasi,
            latitude: this.state.location.lat,
            longitude: this.state.location.lng,
            max_jarak: this.state.radius_lokasi
        }
        this.props.request("lokasi/update", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.setState({
                        modalEdit: false,
                        nama_lokasi: "",
                        location: {
                            lat: -7.865242,
                            lng: 110.156070
                        },
                        tipe_lokasi: "",
                        radius_lokasi: 100,
                        alamat_lokasi: "",
                        disable_edit: false
                    }, () => this.loadData())
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning'
                    })
                }
            })
    }

    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift: response.shift,
                        lokasi: response.lokasi,
                        divisi: response.divisi,
                        karyawan: response.karyawan
                    }, () => {
                        if (this.state.shift.length !== 0 && this.state.lokasi.length !== 0 && this.state.divisi.length !== 0 && this.state.karyawan.length !== 0 && this.props.user.perusahaan.perusahaan_nama !== "") {
                            this.setState({
                                progresModal: true
                            })
                        }
                    })
                }
            })
    }

    loadData() {
        this.setState({
            loadingList: true
        })
        let form = {
            filter_nama: this.state.filter_nama,
            paging: 20,
            page: this.state.currentPage + 1,
        }
        this.props.request("lokasi/list", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                        loadingList: false
                    })
                }
            })
    }

    componentDidMount() {
        this.loadCheck()
        this.loadData()
    }

    render() {
        let no = 0;
        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#143B5E",
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: 700
            },
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B",
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#143B5E",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
            time_input: {
                padding: "12px",
                fontSize: "14px",
                color: "#252B2B",
                backgroundColor: "#FFFFFF",
                lineHeight: "16px",
                width: "88px"
            }
        }
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Daftar Lokasi - iPresens</title>
                    <meta name="description" content="Daftar Jam Kerja" />
                </Helmet>
                <Progres
                    request={this.props.request}
                    loadCheck={() => this.loadCheck()}
                    shift={this.state.shift}
                    lokasi={this.state.lokasi}
                    divisi={this.state.divisi}
                    karyawan={this.state.karyawan}
                    progresModal={this.state.progresModal}
                    setState={(name, val) => this.setState(name, val)}
                    history={this.props.history}
                />
                <Modal visible={this.state.modalAdd} bodyStyle={{ maxWidth: "1000px", padding: 0 }} width={1000}
                    closeIcon={<></>}
                    footer={null}
                    onCancel={() => this.setState({
                        modalAdd: false,
                        nama_lokasi: "",
                        location: {
                            lat: -7.865242,
                            lng: 110.156070
                        },
                        tipe_lokasi: "",
                        radius_lokasi: 100,
                        alamat_lokasi: ""
                    })}>
                    <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                        modalAdd: false,
                        nama_lokasi: "",
                        location: {
                            lat: -7.865242,
                            lng: 110.156070
                        },
                        tipe_lokasi: "",
                        radius_lokasi: 100,
                        alamat_lokasi: ""
                    })}><div style={style_add.title_header}>{i18n.t("tambahlokasi")}</div></ModalHeader>
                    <ModalBody className='p-4'>
                        <div className='row mb-2'>
                            <div className='col-md-12'>
                                <MapPicker
                                    style={{ position: 'relative', width: '100%', height: '250px' }}
                                    name='location'
                                    defaultLocation={this.state.location}
                                    zoom={10}
                                    onChangeLocation={(lat, lng) => {
                                        this.setState({
                                            location: {
                                                lat: lat,
                                                lng: lng
                                            }
                                        })
                                    }}
                                    apiKey={APIKEY} />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-4'>
                                <label className='form-label' style={style_add.label_input}>{i18n.t("namalokasi")}</label>
                                <input className='form-control' type='text' name='nama_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.nama_lokasi} style={style_add.input} placeholder='Masukkan nama lokasi' />
                            </div>
                            <div className='col-md-4'>
                                <label className='form-label' style={style_add.label_input}>{i18n.t("tipelokasi")}</label>
                                <select className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.tipe_lokasi} name="tipe_lokasi" style={style_add.select}>
                                    <option value="pusat">Pusat</option>
                                    <option value="cabang">Cabang</option>
                                </select>
                            </div>
                            <div className='col-md-4'>
                                <label className='form-label' style={style_add.label_input}>{i18n.t("radius")}</label>
                                <div className='input-group'>
                                    <input className='form-control' value={this.state.radius_lokasi} type='number' onChange={(e) => this.handleChange(e)} name='radius_lokasi' style={style_add.input} />
                                    <div className='input-group-append'>
                                        <span className='input-group-text' style={style_add.input_group_text}>Meter</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-4'>
                                <label className='form-label' style={style_add.label_input}>{i18n.t("alamatlokasi")}</label>
                                <input className='form-control' type='text' name='alamat_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.alamat_lokasi} style={style_add.input} placeholder={i18n.t("masukkanalamat")} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn text-capitalize ipresens-btn-cancel' onClick={() => this.setState({
                            modalAdd: false,
                            nama_lokasi: "",
                            location: {},
                            tipe_lokasi: "",
                            radius_lokasi: 100,
                            alamat_lokasi: ""
                        })} style={{ padding: "8px 14px", fontWeight: 800, fontSize: "14px", lineHeight: "24px" }}>{i18n.t("batal")}</button>
                        <button className='btn ipresens-btn-color text-capitalize' disabled={this.state.disable_add} onClick={() => this.simpanLokasi()} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>{i18n.t("tambahlokasi")}</button>
                    </ModalFooter>
                </Modal>
                <Modal visible={this.state.modalEdit} bodyStyle={{ maxWidth: "1000px", padding: 0 }} width={1000}
                    closeIcon={<></>}
                    footer={null}
                    onCancel={() => this.setState({
                        modalEdit: false,
                        nama_lokasi: "",
                        location: {},
                        tipe_lokasi: "",
                        radius_lokasi: 100,
                        alamat_lokasi: ""
                    })}>
                    <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                        modalEdit: false,
                        nama_lokasi: "",
                        location: {},
                        tipe_lokasi: "",
                        radius_lokasi: 100,
                        alamat_lokasi: ""
                    })}><div style={style_add.title_header}>Edit Lokasi</div></ModalHeader>
                    <ModalBody className='p-4'>
                        <div className='row mb-2'>
                            <div className='col-md-12'>
                                <MapPicker
                                    style={{ position: 'relative', width: '100%', height: '250px' }}
                                    name='location'
                                    defaultLocation={this.state.location}
                                    zoom={10}
                                    onChangeLocation={(lat, lng) => {
                                        this.setState({
                                            location: {
                                                lat: lat,
                                                lng: lng
                                            }
                                        })
                                    }}
                                    apiKey={APIKEY} />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-4'>
                                <label className='form-label' style={style_add.label_input}>Nama Lokasi</label>
                                <input className='form-control' type='text' name='nama_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.nama_lokasi} style={style_add.input} placeholder='Masukkan nama lokasi' />
                            </div>
                            <div className='col-md-4'>
                                <label className='form-label' style={style_add.label_input}>Tipe Lokasi</label>
                                <select className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.tipe_lokasi} name="tipe_lokasi" style={style_add.select}>
                                    <option value="pusat">Pusat</option>
                                    <option value="cabang">Cabang</option>
                                </select>
                            </div>
                            <div className='col-md-4'>
                                <label className='form-label' style={style_add.label_input}>Radius</label>
                                <div className='input-group'>
                                    <input className='form-control' value={this.state.radius_lokasi} type='number' onChange={(e) => this.handleChange(e)} name='radius_lokasi' style={style_add.input} />
                                    <div className='input-group-append'>
                                        <span className='input-group-text' style={style_add.input_group_text}>Meter</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-4'>
                                <label className='form-label' style={style_add.label_input}>Alamat Lokasi</label>
                                <input className='form-control' type='text' name='alamat_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.alamat_lokasi} style={style_add.input} placeholder={i18n.t("masukkanalamat")} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn ipresens-btn-cancel text-capitalize' onClick={() => this.setState({
                            modalEdit: false,
                            nama_lokasi: "",
                            location: {},
                            tipe_lokasi: "",
                            radius_lokasi: 100,
                            alamat_lokasi: ""
                        })} style={{ padding: "8px 14px", fontWeight: 800, fontSize: "14px", lineHeight: "24px" }}>Batalkan</button>
                        <button className='btn ipresens-btn-color text-capitalize' disabled={this.state.disable_edit} onClick={() => this.editLokasi()} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>Edit Lokasi</button>
                    </ModalFooter>
                </Modal>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12 px-0'>
                            <div className='card border'>
                                <div className='card-header border-bottom' style={style.card_header}>Lokasi</div>
                                <div className='card-body p-4'>
                                    <div className='row mb-3'>
                                        {/* <div className='col-md-5 pr-1'>
                                            <div className='input-group'>
                                                <div className='input-group-prepend'>
                                                    <div className='input-group-text border-right-0' style={{ backgroundColor: "#F9F9F9" }}>
                                                        <img alt='' src={SearchIcon} />
                                                    </div>
                                                </div>
                                                <input name="filter_nama" type='text' className='form-control border-left-0' onChange={(e) => this.handleChange(e)} placeholder='Cari nama Lokasi ...' style={style.filter_nama} value={this.state.filter_nama} onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        this.loadData()
                                                    }
                                                }}></input>
                                            </div>
                                        </div>
                                        <div className='col-md-1 tombol-refresh'>
                                            <button className='hover-pointer' style={style.button_refresh}><img alt="" src={RefreshIcon} onClick={() => {
                                                this.setState({
                                                    filter_nama: ""
                                                }, () => this.loadData())
                                            }} /></button>
                                        </div>
                                        <div className='col-md'>
                                            <button onClick={() => {
                                                this.setState({
                                                    modalAdd: true,
                                                    location: {
                                                        lat: -7.865242,
                                                        lng: 110.156070
                                                    },
                                                })
                                            }} className='btn btn-primary text-capitalize pull-right button-w-full'><img className='mr-2' alt='' src={PlusWhiteIcon} />Tambah Lokasi</button>
                                        </div> */}
                                        <div className="col-md-6 mb-2">
                                            <div className='d-flex flex-row'>
                                                <div className='flex-fill pr-2'>
                                                    <div className='input-group'>
                                                        <div className='input-group-prepend'>
                                                            <div className='input-group-text border-right-0' style={{ backgroundColor: "#F9F9F9" }}>
                                                                <img alt='' src={SearchIcon} />
                                                            </div>
                                                        </div>
                                                        <input name="filter_nama" type='text' className='form-control border-left-0' onChange={(e) => this.handleChange(e)} placeholder={i18n.t("carinamalokasi")+'...'} style={style.filter_nama} value={this.state.filter_nama} onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.loadData()
                                                            }
                                                        }}></input>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='hover-pointer' style={style.button_refresh}><img alt="" src={RefreshIcon} onClick={() => {
                                                        this.setState({
                                                            filter_nama: ""
                                                        }, () => this.loadData())
                                                    }} /></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <button onClick={() => {
                                                this.setState({
                                                    modalAdd: true,
                                                    location: {
                                                        lat: -7.865242,
                                                        lng: 110.156070
                                                    },
                                                })
                                            }} className='btn ipresens-btn-color text-capitalize pull-right button-w-full'><img className='mr-2' alt='' src={PlusWhiteIcon} />{i18n.t("tambahlokasi")}</button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='table-responsive'>
                                                <table className='table table-striped table-borderless'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                width: "30px"
                                                            }}>#</th>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "50px",
                                                                maxWidth: "447px"
                                                            }}>{i18n.t("nama")}</th>
                                                            <th style={style.th_style}>{i18n.t("radius")}</th>
                                                            <th style={style.th_style}>{i18n.t("tipelokasi")}</th>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "140px"
                                                            }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.loadingList ?
                                                            <tr>
                                                                <td colSpan={5} style={{ textAlign: "center" }}>
                                                                    <Spin size="large" />
                                                                </td>
                                                            </tr>
                                                            :
                                                            this.state.data.length === 0 ?
                                                                <tr>
                                                                    <td colSpan={5} style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}>
                                                                        <img src={EmptyDataImage} className="ipresens-image-empty" alt="icon data kosong" />
                                                                        <p className='mb-2'>{i18n.t("datakosong")}...</p>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                this.state.data.map((item, index) => {
                                                                    no += 1
                                                                    return (
                                                                        <tr>
                                                                            <td style={style.td_style}>{no}</td>
                                                                            <td style={style.td_style}>
                                                                                <div className='row m-0' style={{
                                                                                    fontWeight: 700,
                                                                                    fontSize: "14px",
                                                                                    color: "#252B2B",
                                                                                    lineHeight: "24px"
                                                                                }}>{item.lokasi_nama}</div>
                                                                            </td>
                                                                            <td style={style.td_style}>{item.max_jarak}m</td>
                                                                            <td className='text-capitalize' style={style.td_style}>{item.perusahaan_title}</td>
                                                                            <td className='text-right' style={style.td_style}>
                                                                                <button className='hover-pointer' style={{
                                                                                    backgroundColor: "transparent",
                                                                                    border: "0px"
                                                                                }} onClick={() => {
                                                                                    this.setState({
                                                                                        id_lokasi: item.lokasi_id
                                                                                    }, () => {
                                                                                        this.detailLokasi()
                                                                                    })
                                                                                }}>
                                                                                    <img alt='' src={PencilIcon} />
                                                                                </button>
                                                                                <button className='hover-pointer' style={{
                                                                                    backgroundColor: "transparent",
                                                                                    border: "0px"
                                                                                }} onClick={() => {
                                                                                    Swal.fire({
                                                                                        title: 'Apakah anda yakin?',
                                                                                        text: "Anda tidak dapat mengembalikan data ini setelah dihapus!",
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonColor: '#3085d6',
                                                                                        cancelButtonColor: '#d33',
                                                                                        confirmButtonText: 'Hapus',
                                                                                        cancelButtonText: "Batal"
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            this.setState({
                                                                                                id_lokasi: item.lokasi_id
                                                                                            }, () => this.hapusLokasi())
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                                    <img alt='' src={TrashIcon} />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer p-0'>
                                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                        {/* {this.state.data.length !== 0 && */}
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}</div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeft} alt='' />}
                                                    nextLabel={<img src={ArrowRight} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                                    activeClassName={'active custom-active'}
                                                />
                                                {/* catatan.
                                                custom classname pagination dipanggil dari file css di folder absensi harian
                                                ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini */}
                                            </div>
                                        </>
                                        {/* // } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LokasiGuide);
