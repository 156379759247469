import React, {Component, Fragment} from 'react';
import Breadcrumb from './common/breadcrumb';
import DateCountdown from 'react-date-countdown-timer';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
// import {Bar} from 'react-chartjs-2';
import {Helmet} from "react-helmet";
// import {lineOptions,} from '../constants/chartData'
import Tour from "reactour";
import {salam} from "../helper/hello";
import UserIcon from '../assets/icons/dashboard/user.svg'
import LocationIcon from '../assets/icons/dashboard/location.svg'
import PengumumanIcon from '../assets/icons/dashboard/pengumuman.svg'
import LanggananIcon from '../assets/icons/dashboard/langganan.svg'
import Swal from "sweetalert2";
import {clear_auth, set_auth} from "../reducers/Auth";
import {Label} from "reactstrap";
import {connect} from "react-redux";
// import Chart from "react-google-charts";
import ArrowRightWhiteIcon from "../assets/icons/arrow-right-white.svg";
import Progres from './menu-guide/progres';
import moment from 'moment';
import {Modal} from 'antd';
import {toast} from "react-toastify";
import Survey from "./common/Survey";
import Slideshow from "./common/slideshow";
import {Link} from "react-router-dom";
import i18n from "i18next";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        const now = new Date();
        this.state = {
            data: [],
            perusahaan: [],
            showModal: false,
            absensi: [],
            modalConfirm: false,
            modalTimezone: false,
            nowaconfirm: "",
            otpwaconfirm: "",
            dataSurvey  : '',
            survey      :"",
            loading: true,
            notScrollable: false,
            time: {},
            seconds: 30,
            guide: localStorage.getItem('Presensi-Guide'),
            p_user_name: "",
            perusahaan_nama: "",
            perusahaan_bidang: "",
            perusahaan_alamat: "",
            perusahaan_timezone: this.props.user.perusahaan.perusahaan_timezone !== null ? this.props.user.perusahaan.perusahaan_timezone : "",
            perusahaan_telp: "",
            lokasi: [],
            divisi: [],
            shift: [],
            karyawan: [],
            absen: [],
            karyawanData: [],
            selectedBulanTahun: moment().format('MM'),
            progresModal: false,
            dontShowAgain: false,
            modalSlideshow: false,
            timer: 0,
            history:[],
            agenda: [],
            agendaAdmin: [],
        };
        this.timer = 0;
        this.handleChange = this.handleChange.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.showModalAgain = this.showModalAgain.bind(this);
    }


    disableBody = target => disableBodyScroll(target);
    enableBody = target => enableBodyScroll(target);


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    updatePerusahaan() {
        let form = {
            perusahaan_id: this.props.user.perusahaan.perusahaan_id,
            perusahaan_nama: this.state.perusahaan_nama,
            perusahaan_bidang: this.state.perusahaan_bidang,
            perusahaan_alamat: this.state.perusahaan_alamat,
            perusahaan_timezone: this.state.perusahaan_timezone,
            perusahaan_telp: this.state.perusahaan_telp
        }
        this.props.request("dashboard-v2", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: "Berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.props.set_auth(this.props.api_token, response.data)
                            window.location.reload(true)
                        } else {
                            this.props.set_auth(this.props.api_token, response.data)
                            window.location.reload(true)
                        }
                    })
                } else {
                    Swal.fire({
                        title: "Gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    handleSave(){
        let formData ={
            survey_content        : this.state.survey,
        }
        this.props.request('last-survey', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        dataSurvey      : response.data,
                        isLoading       : false,
                        showModal       : false,
                    });
                }
                else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
                this.setState({ isLoading: false })
            })
    }

    sendOTP() {
        if (this.state.disableSendOTP) {
            return;
        }
        this.setState({
            loading: true
        });
        let form = {
            perusahaan_no_wa: this.state.nowaconfirm,
        };
        this.props.request("otp-wa", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        otpwaForm: true,
                    });
                    this.startTimer()
                }
                this.setState({
                    loading: false
                })
            });
    }

    verifOTP() {
        this.setState({
            loading: true
        });
        let form = {
            perusahaan_no_wa: this.state.nowaconfirm,
            perusahaan_otp_wa: this.state.otpwaconfirm,
        };
        this.props.request("verif-wa", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                    });
                    this.setState({
                        modalConfirm: false
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            disableSendOTP: true,
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds == 0) {
            clearInterval(this.timer);
            this.setState({
                disableSendOTP: false,
                seconds: 30,
            })
        }
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    loadBanner() {
        this.props.request("banner-cms", {}, 'POST')
        .then(response => {
            const bannerValue = response.data.setting_value;
            if (bannerValue === "") {
                this.setState({modalSlideshow: false});
            } else {
                const checkingBanner = bannerValue === null;
                const today = new Date().toISOString().slice(0,10);
                const lastDate = localStorage.getItem("lastDate");
                if (lastDate !== today) {
                    this.setState({modalSlideshow: true});
                    localStorage.setItem("lastDate", today);
                }

                if (response.success) {
                    this.setState({
                        modalSlideshow: [checkingBanner],
                    })

                }
            }
        })
    }

    loadData() {
        this.setState({
            loading: true
        });
        let form = {};
        this.props.request("dashboard", form, 'POST')
            .then(response => {
                if (response.success) {
                    const isKaryawanNotNull = response.data.karyawan !== null;
                    const showModalValue = response.data.perusahaan_last_survey;
                    const original_last_survey_date = new Date(response.data.perusahaan_last_survey)

                    let last_survey_date;
                    if(showModalValue != null){
                        last_survey_date = new Date(original_last_survey_date.setMonth(original_last_survey_date.getMonth()+3));
                    }else{
                        let verified_date = new Date(response.data.verified_at)
                        last_survey_date = new Date(verified_date.setMonth(original_last_survey_date.getMonth()+3));
                    }

                    const showModalData = last_survey_date < new Date();

                    this.setState({
                        showModal: showModalData,
                        data: response,
                        perusahaan: isKaryawanNotNull ? response.data : {
                            ...response.data,
                            karyawan: {
                                karyawan_laki_laki: 0,
                                karyawan_perempuan: 0,
                                total_karyawan: 0,
                                sisa_karyawan: 0,
                            }
                        }
                    });
                }
                this.setState({
                    loading: false
                });
            });
    }

    loadCheck() {
        let form = {
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({

                    }, () => {
                        if (this.props.user.perusahaan.status === "new" && this.props.user.perusahaan.perusahaan_bidang !== null && this.props.user.perusahaan.perusahaan_alamat !== null && this.props.user.perusahaan.perusahaan_telp !== null) {
                            this.setState({
                                progresModal: true
                            })
                        }
                    })
                }
            })
    }

    loadAdminHistoryList() {
        this.props.request("list_history_admin", {}, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        history: response.data.data,
                    })
                }
            })
    }

    loadAbsen() {
        this.props.request("notif-absen", {}, "POST")
            .then((response) => {
            if (response.success) {
                this.setState({
                    absen: response.data,
                    karyawanData: response.data.karyawan,
                })
            }
        })
    }

    closeModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    // checkUpdateFiturBanner(){
    //     const dashVs = localStorage.getItem("dashVs");
    //     this.setState({
    //         updateFiturModal: true,
    //         updateFiturText: dataDashboardVersion[dataVersion["dashboardversion"]]
    //     })
    //     if(dashVs){
    //         if(parseFloat(dashVs) >= parseFloat(dataVersion["dashboardversion"])){
    //             this.setState({
    //                 updateFiturModal: false,
    //             })
    //         }
    //     }
    // }

    loadAgenda() {
        let form = {

        }
        this.props.request("date-agenda", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        agendaAdmin: response.admin,
                        agenda: response.data,
                    })
                }
            })
    }


    componentWillUnmount() {
        // Simpan data gambar ke localStorage saat komponen di-unmount
        localStorage.setItem('slideshowImages', JSON.stringify(this.state.images));
    }

    componentDidMount() {
        this.loadData();
        // this.checkUpdateFiturBanner()
        this.loadAgenda();
        this.loadCheck();
        this.loadAbsen();
        this.loadAdminHistoryList();
        // this.checkLocalStorage();
        this.loadBanner();
        // this.checkModalTimezone()
        const now = new Date();
        const currentMonth = now.getMonth() + 1;
        const monthsToShow = 6;
        const options = [];

        for (let i = 0; i < monthsToShow; i++) {
            const monthValue = currentMonth - i > 0 ? currentMonth - i : currentMonth - i + 12;
            options.push(
                <option key={monthValue} value={monthValue.toString().padStart(2, '0')}>
                    {this.getMonthName(monthValue)}
                </option>
            );
        }
    }

    handleCheckboxChange = (event) => {
        this.setState({ dontShowAgain: event.target.checked });
    }

    handleClose = () => {
        this.props.request('last-survey-p2', {}, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        dataSurvey      : response.data,
                        isLoading       : false,
                        showModal       : false,
                    });
                }
                else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
                this.setState({ isLoading: false })
            })
    }

    handleCancel = () => {
        const currentDate = new Date();
        localStorage.setItem('lastShown', currentDate.toString());
        this.setState({ showModal: false });
    }

    showModalAgain() {
        localStorage.removeItem('lastShown');
        localStorage.removeItem('dontShowAgain');
        this.setState({showModal:true});
    }

    getMonthName(monthValue) {
        const months = [
            "Januari", "Februari", "Maret", "April", "Mei", "Juni",
            "Juli", "Agustus", "September", "Oktober", "November", "Desember"
        ];

        if (monthValue >= 1 && monthValue <= 12) {
            return months[monthValue - 1];
        } else {
            return "Bulan tidak valid";
        }
    }

    submitPerusahaan() { }

    render() {
        const { agendaModal, selectedEvent } = this.state;
        const agendaData = [
            ...this.state.agendaAdmin.map((item) => ({
                title: item.title,
                start: new Date(item.tahun, item.bulan, item.hari),
                end: new Date(item.tahun, item.bulan, item.hari),
                desc: item.note
            })),
            ...this.state.agenda.map((item) => ({
                // Map the agenda items as needed
                title: item.title,
                start: new Date(item.tahun, item.bulan, item.hari),
                end: new Date(item.tahun, item.bulan, item.hari),
                desc: item.note
            }))
        ];

        const steps = [{
            selector: '.card-karyawan',
            content: 'Anda dapat melihat jumlah Karyawan anda disini',
        },];
        const style_card = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#143B5E",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 700,
                padding: "10px 16px"
            },
            card_body: {
                padding: "16px"
            },
            label: {
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "Lato",
                color: "#143B5E",
            },
            input: {
                padding: "12px",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "Lato",
                color: "#252B2B",
            },
            input_select: {
                padding: "12px 12px 10px",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                fontFamily: "Lato",
                color: "#252B2B",
            },
            card_footer: {
                padding: "16px"
            },
            button: {
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: 500,
                color: "#FFFFFF",
                padding: "8px 14px",
            }
        }
        let dashboard;
        if (this.props.user.perusahaan.perusahaan_bidang === null && this.props.user.perusahaan.perusahaan_alamat === null && this.props.user.perusahaan.perusahaan_telp === null) {
            dashboard =
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='card border'>
                                <div className='card-header border-bottom' style={style_card.card_header} >{i18n.t("profilperusahaan")}</div>
                                <div className='card-body' style={style_card.card_body}>
                                    <div className=''>
                                        <label className='form-label' style={style_card.label} >{i18n.t("namaperusahaan")}</label>
                                        <input className='form-control' type='text' value={this.state.perusahaan_nama} style={style_card.input} onChange={this.handleChange} name='perusahaan_nama' />
                                    </div>
                                    <div className=''>
                                        <label className='form-label' style={style_card.label}>{i18n.t("bidangperusahaan")}</label>
                                        <input className='form-control' type='text' value={this.state.perusahaan_bidang} style={style_card.input} onChange={this.handleChange} name='perusahaan_bidang' />
                                    </div>
                                    <div className=''>
                                        <label className='form-label' style={style_card.label}>{i18n.t("alamatperusahaan")}</label>
                                        <textarea className='form-control' value={this.state.perusahaan_alamat} style={style_card.input} onChange={this.handleChange} name='perusahaan_alamat' />
                                    </div>
                                    <div className=''>
                                        <label className='form-label' style={style_card.label}>{i18n.t("zonawaktu")}</label>
                                        {/* <input className='form-control' type='text' style={style_card.input} /> */}
                                        <select name="perusahaan_timezone" className="form-control" style={style_card.input_select} onChange={this.handleChange}>
                                            {[
                                                {
                                                    value: "gmt-12",
                                                    label: "GMT-12"
                                                },
                                                {
                                                    value: "gmt-11",
                                                    label: "GMT-11"
                                                },
                                                {
                                                    value: "gmt-10",
                                                    label: "GMT-10"
                                                },
                                                {
                                                    value: "gmt-9",
                                                    label: "GMT-9"
                                                },
                                                {
                                                    value: "gmt-8",
                                                    label: "GMT-8"
                                                },
                                                {
                                                    value: "gmt-7",
                                                    label: "GMT-7"
                                                },
                                                {
                                                    value: "gmt-6",
                                                    label: "GMT-6"
                                                },
                                                {
                                                    value: "gmt-5",
                                                    label: "GMT-5"
                                                },
                                                {
                                                    value: "gmt-4",
                                                    label: "GMT-4"
                                                },
                                                {
                                                    value: "gmt-3",
                                                    label: "GMT-3"
                                                },
                                                {
                                                    value: "gmt-2",
                                                    label: "GMT-2"
                                                },
                                                {
                                                    value: "gmt-1",
                                                    label: "GMT-1"
                                                },
                                                {
                                                    value: "gmt+0",
                                                    label: "GMT+0"
                                                },
                                                {
                                                    value: "gmt+1",
                                                    label: "GMT+1"
                                                },
                                                {
                                                    value: "gmt+2",
                                                    label: "GMT+2"
                                                },
                                                {
                                                    value: "gmt+3",
                                                    label: "GMT+3"
                                                },
                                                {
                                                    value: "gmt+4",
                                                    label: "GMT+4"
                                                },
                                                {
                                                    value: "gmt+5",
                                                    label: "GMT+5"
                                                },
                                                {
                                                    value: "gmt+6",
                                                    label: "GMT+6"
                                                },
                                                {
                                                    value: "wib",
                                                    label: "GMT+7 (WIB)"
                                                },
                                                {
                                                    value: "wita",
                                                    label: "GMT+8 (WITA)"
                                                },
                                                {
                                                    value: "wit",
                                                    label: "GMT+9 (WIT)"
                                                },
                                                {
                                                    value: "gmt+10",
                                                    label: "GMT+10"
                                                },
                                                {
                                                    value: "gmt+11",
                                                    label: "GMT+11"
                                                },
                                                {
                                                    value: "gmt+12",
                                                    label: "GMT+12"
                                                },
                                            ].map((item) => (
                                                <option
                                                    key={item.value}
                                                    value={item.value}
                                                    selected={this.state.perusahaan_timezone === item.value}
                                                >
                                                    {item.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className=''>
                                        <label className='form-label' style={style_card.label}>{i18n.t("nohpperusahaan")}</label>
                                        <input className='form-control' value={this.state.perusahaan_telp} type='number' style={style_card.input} onChange={this.handleChange} name='perusahaan_telp' />
                                    </div>
                                </div>
                                <div className='card-footer' style={style_card.card_footer}>
                                    <button className='btn btn-primary pull-right text-capitalize' style={style_card.button} onClick={() => this.updatePerusahaan()}>{i18n.t("selanjutnya")} <img alt='' src={ArrowRightWhiteIcon} /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'></div>
                    </div>
                </div>
        }

        else {
            dashboard =
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-md-3">
                                <a href={
                                    "/karyawan/list-karyawan/0"
                                }>
                                    <div className="card o-hidden">
                                        <div className="bg-white card-body"
                                            style={
                                                {
                                                    padding: 24
                                                }
                                            }>
                                            <div className="media static-top-widget row">
                                                <div className="icons-widgets col-4">
                                                    <div className="align-self-center text-center">
                                                        <img src={
                                                            UserIcon
                                                        }
                                                            alt="Menu Icon" />
                                                    </div>
                                                </div>
                                                <div className="media-body col-8"> <span className="m-0"
                                                    style={
                                                        {
                                                            color: '#5D6F80'
                                                        }
                                                    }> {i18n.t("karyawan")} </span>
                                                    <h3 className="mb-0"
                                                        style={
                                                            {
                                                                color: '#143B5E'
                                                            }
                                                        }> {
                                                            this.state.loading ?
                                                                <p> {i18n.t("sedangmemuatdata")}... </p> :
                                                                this.state.data.jumlah_karyawan}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div
                                className="col-xl-3 col-md-3">
                                <a href={
                                    "/lokasi-presensi"
                                }>
                                    <div
                                        className="card o-hidden">
                                        <div
                                            className="bg-white card-body"
                                            style={
                                                {
                                                    padding: 24
                                                }
                                            }>
                                            <div
                                                className="media static-top-widget row">
                                                <div
                                                    className="icons-widgets col-4">
                                                    <div
                                                        className="align-self-center text-center">
                                                        <img
                                                            src={
                                                                LocationIcon
                                                            }
                                                            alt="Menu Icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="media-body col-8"> < span
                                                        className="m-0"
                                                        style={
                                                            {
                                                                color: '#5D6F80'
                                                            }
                                                        }> {i18n.t('lokasiabsen')} </span>
                                                    <h3
                                                        className="mb-0"
                                                        style={
                                                            {
                                                                color: '#143B5E'
                                                            }
                                                        }> {
                                                            this.state.loading ?
                                                                <
                                                                    p> {i18n.t("sedangmemuatdata")}... </p> :
                                                                this.state.data.jumlah_lokasi}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-xl-3 col-md-3">
                                <a href={
                                    "/pengumuman/list"
                                }>
                                    <div
                                        className="card o-hidden">
                                        <div
                                            className="bg-white card-body"
                                            style={
                                                {
                                                    padding: 24
                                                }
                                            }>
                                            <div
                                                className="media static-top-widget row">
                                                <div
                                                    className="icons-widgets col-4">
                                                    <div
                                                        className="align-self-center text-center">
                                                        <img
                                                            src={
                                                                PengumumanIcon
                                                            }
                                                            alt="Menu Icon" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="media-body col-8"> <span
                                                        className="m-0"
                                                        style={
                                                            {
                                                                color: '#5D6F80'
                                                            }
                                                        }> {i18n.t("pengumuman")} </span> <h3
                                                            className="mb-0"
                                                            style={
                                                                {
                                                                    color: '#143B5E'
                                                                }
                                                            }> {
                                                            this.state.loading ?
                                                                <p> {i18n.t("sedangmemuatdata")}... </p> :
                                                                this.state.data.jumlah_pengumuman}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div
                                className="col-xl-3 col-md-3">
                                <a href={"/langganan"}>
                                    <div className="card o-hidden">
                                        <div className="bg-white card-body" style={{ padding: 24 }}>
                                            <div className="media static-top-widget row">
                                                <div
                                                    className="icons-widgets col-4">
                                                    <div
                                                        className="align-self-center text-center">
                                                        <img
                                                            src={
                                                                LanggananIcon
                                                            }
                                                            alt="Menu Icon" />
                                                    </div>
                                                </div>
                                                <div className="media-body col-8">
                                                    <span className="m-0" style={{ color: '#5D6F80' }}> {i18n.t('sisalangganan')} </span><br />
                                                    <span className="mb-0" style={{ color: '#143B5E' }}> {this.state.loading ? <h3> {i18n.t("sedangmemuatdata")}... </h3> : <span>
                                                        <DateCountdown
                                                            dateTo={
                                                                this.state.data.expired_date.langganan_expired_date
                                                            }
                                                            numberOfFigures={
                                                                2
                                                            }
                                                            locales={
                                                                [
                                                                    i18n.t('tahun'),
                                                                    i18n.t('bulan'),
                                                                    i18n.t('hari'),
                                                                    i18n.t('jam'),
                                                                    i18n.t('menit'),
                                                                    i18n.t('detik')
                                                                ]
                                                            }
                                                            locales_plural={
                                                                [
                                                                    i18n.t('tahun'),
                                                                    i18n.t('bulan'),
                                                                    i18n.t('hari'),
                                                                    i18n.t('jam'),
                                                                    i18n.t('menit'),
                                                                    i18n.t('detik')
                                                                ]
                                                            }
                                                            callback={
                                                                () => {
                                                                    Swal.fire({
                                                                        title: 'Pemberitahuan!',
                                                                        text: 'Masa Berlangganan anda telah habis. Silahkan bayar tagihan untuk memperbarui Langganan!',
                                                                        icon: 'warning',
                                                                        confirmButtonText: 'Bayar Tagihan'
                                                                    })
                                                                        .then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                this.props.history.push('/langganan')
                                                                            } else {
                                                                                this.props.history.push('/langganan')
                                                                            }
                                                                        })

                                                                }
                                                            }
                                                        /> </span>
                                                    } </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </a></div>
                        </div>

                        <Modal
                            title="Konfirmasi Nomor WA"
                            visible={
                                this.state.modalConfirm
                            }
                            closable={
                                false
                            }
                            footer={
                                null
                            }>
                            <div>
                                <Label> Perusahaan Wajib Mengisi No Whatsapp dan Melakukan Konfirmasi dengan
                                    OTP </Label><br />
                                <div className="row">
                                    <div className="col-8 my-auto">
                                        <input required=""
                                            max="12"
                                            name="nowaconfirm"
                                            onChange={
                                                this.handleChange
                                            }
                                            type="text"
                                            className={
                                                'form-control'
                                            }
                                            placeholder="No. WhatsApp Perusahaan"
                                            value={
                                                this.state.nowaconfirm
                                            }
                                        /></div>
                                    <div className="col-4 my-auto">
                                        <a type="button"
                                            className={
                                                "btn btn-primary mb-3 mt-3 text-center" + (this.state.disableSendOTP && "disabled")
                                            }
                                            style={
                                                {
                                                    color: 'white',
                                                    maxWidth: '100%'
                                                }
                                            }
                                            onClick={
                                                () => {
                                                    this.sendOTP()
                                                }
                                            }> {
                                                !this.state.disableSendOTP ? "Kirim OTP" : this.state.time.s + " detik"
                                            } </a></div>
                                </div>
                                {
                                    this.state.otpwaForm &&
                                    <div className="row">
                                        <div className="col-6 my-auto">
                                            <input required=""
                                                max="12"
                                                name="otpwaconfirm"
                                                onChange={
                                                    this.handleChange
                                                }
                                                type="text"
                                                className={
                                                    'form-control'
                                                }
                                                placeholder="No. WhatsApp Perusahaan"
                                                value={
                                                    this.state.otpwaconfirm
                                                }
                                            />
                                        </div>
                                        <div className="col-5 my-auto">
                                            <a type="button"
                                                className="btn btn-primary mb-3 mt-3 text-center"
                                                style={
                                                    {
                                                        color: 'white',
                                                        maxWidth: '100%'
                                                    }
                                                }
                                                onClick={
                                                    () => {
                                                        this.verifOTP()
                                                    }
                                                }>
                                                Verifikasi WA </a>
                                        </div>
                                    </div>
                                }</div>
                        </Modal>
                        <Modal title="Timezone Perusahaan"
                            visible={
                                this.state.modalTimezone
                            }
                            footer={
                                null
                            }>
                            <div>
                                <Label> Perusahaan Wajib Mengisi No Whatsapp dan
                                    Melakukan Konfirmasi dengan OTP </Label><br />
                                <div className="row">
                                    <div className="col-8 my-auto">
                                        <input required=""
                                            max="12"
                                            name="nowaconfirm"
                                            onChange={
                                                this.handleChange
                                            }
                                            type="text"
                                            className={
                                                'form-control'
                                            }
                                            placeholder="No. WhatsApp Perusahaan"
                                            value={
                                                this.state.nowaconfirm
                                            }
                                        />
                                    </div>
                                    <div className="col-4 my-auto">
                                        <a type="button"
                                            className={
                                                "btn btn-primary mb-3 mt-3 text-center" + (this.state.disableSendOTP && "disabled")
                                            }
                                            style={
                                                {
                                                    color: 'white',
                                                    maxWidth: '100%'
                                                }
                                            }
                                            onClick={
                                                () => {
                                                    this.sendOTP()
                                                }
                                            }> {
                                                !this.state.disableSendOTP ? "Kirim OTP" : this.state.time.s + " detik"
                                            } </a>
                                    </div>
                                </div>
                                {
                                    this.state.otpwaForm &&
                                    <div className="row">
                                        <div
                                            className="col-6 my-auto">
                                            <input
                                                required=""
                                                max="12"
                                                name="otpwaconfirm"
                                                onChange={
                                                    this.handleChange
                                                }
                                                type="text"
                                                className={
                                                    'form-control'
                                                }
                                                placeholder="No. WhatsApp Perusahaan"
                                                value={
                                                    this.state.otpwaconfirm
                                                }
                                            />
                                        </div>
                                        <div
                                            className="col-5 my-auto">
                                            <a
                                                type="button"
                                                className="btn btn-primary mb-3 mt-3 text-center"
                                                style={
                                                    {
                                                        color: 'white',
                                                        maxWidth: '100%'
                                                    }
                                                }
                                                onClick={
                                                    () => {
                                                        this.verifOTP()
                                                    }
                                                }>
                                                Verifikasi
                                                WA </a>
                                        </div>
                                    </div>
                                } </div>
                        </Modal>
                        {agendaModal === true && selectedEvent === true && (
                            <Modal
                                title="Agenda Detail"
                                visible={agendaModal}
                                onClose={this.closeModalAgenda}
                            >
                                <div>
                                    <h2>{selectedEvent.title}</h2>
                                    <h2>{selectedEvent.start}</h2>
                                    <h2>{selectedEvent.end}</h2>
                                    <p>{selectedEvent.note}</p>
                                    <p>
                                        {`Start: ${selectedEvent.start.toLocaleDateString()} End: ${selectedEvent.end.toLocaleDateString()}`}
                                    </p>
                                </div>
                            </Modal>
                        )}
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 card bg-white">
                                <div className="card-header">
                                    <h5>{i18n.t("daftaraktivitas")}</h5>
                                </div>
                                <div className="card-body">
                                    <ul style={{padding: '4px'}}>
                                        {this.state.absen.length > 0 &&
                                            this.state.absen.map((item, index) => {
                                                return (
                                                    item.karyawan ?
                                                        <>
                                                            <li key={index} className="mb-2"><a
                                                                href={"/karyawan/profil/detail/" + item.karyawan.karyawan_slug}>{item.karyawan.karyawan_nama}</a> melakukan
                                                                absen masuk
                                                                di {item.lokasi ? item.lokasi.lokasi_nama : ""} pada
                                                                jam {item.jam_masuk_absen}</li>
                                                            <br/>
                                                        </> : null
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            {this.state.history.length !== 0 && (
                            <div className="col-md-4 card bg-white ml-auto">
                                <div className="card-header ">
                                    <h5>Daftar History Admin</h5>
                                </div>
                                <div className="card-body">
                                    <ul style={{padding: '4px'}}>
                                        {this.state.history.length !== 0 &&
                                            this.state.history.slice(0, 5).map((item, index) => {
                                                return (
                                                    <>
                                                        <li key={index} className="mb-2">{item.user ? item.user.p_user_name : "nama admin tidak ada"} melakukan
                                                            perubahan {item.history_id ? item.type : "x"} pada {item.created_at}</li>
                                                        <br/>
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='card-footer'>
                                    <div className='pull-right'>
                                        <div className="col-auto">
                                            <Link to="/history-admin"
                                                  className="text-primary"
                                                  style={{lineHeight: "23px"}}>Lihat semua</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </>
        }

        return (
            <Fragment>
                {this.state.showModal &&
                    <Survey
                        showModal={this.state.showModal}
                        toggle={this.closeModal}
                        valueSurvey={this.state.survey}
                        setState={(val, callback) => this.setState(val, callback)}
                        handlerClose={this.handleClose}
                        handlerCheckBoxChanges={this.handleCheckboxChange}
                        saveHandler={this.handleSave}
                        cancel={this.handleClose}
                        dontshow={this.state.dontShowAgain}
                    showModals={this.showModals}
                />
                }

                {this.state.modalSlideshow &&
                    <Slideshow
                    setState={(val, callback) => this.setState(val, callback)}
                    modalSlideshow={this.state.modalSlideshow}
                    request={this.props.request}
                    loading={this.state.loading}
                    />
                }
                <Helmet>
                    <meta charSet="utf-8" />
                    <title> Dashboard - Presensi </title>
                    <meta name="description"
                        content="Detail Absensi" />
                </Helmet>
                {this.props.user.perusahaan.status === "new" && this.props.user.p_user_name !== null && this.props.user.perusahaan.perusahaan_bidang !== null && this.props.user.perusahaan.perusahaan_alamat !== null && this.props.user.perusahaan.perusahaan_telp !== null ?
                    <Progres history={this.props.history} request={this.props.request} loadCheck={() => this.loadCheck()} shift={this.state.shift} lokasi={this.state.lokasi} divisi={this.state.divisi} karyawan={this.state.karyawan} progresModal={this.state.progresModal} setState={(name, val) => this.setState(name, val)} />
                    :
                    <div></div>
                }
                <Breadcrumb
                    header={i18n.t("perusahaan") + " " + this.props.user.perusahaan.perusahaan_nama}
                    title={this.props.user.p_user_name === null && this.props.user.perusahaan.perusahaan_bidang === null && this.props.user.perusahaan.perusahaan_alamat === null && this.props.user.perusahaan.perusahaan_telp === null ?
                        i18n.t("selamatdatang") : salam() + this.props.user.p_user_name
                    }
                    hideBreadcumb={
                        true
                    }
                    setGuide={
                        () => {
                            this.setState({
                                guide: true
                            })
                        }
                    }
                    showUpdateFiturBanner={() => {
                        this.setState({
                            updateFiturModal: true,
                            // updateFiturText: dataDashboardVersion[dataVersion["dashboardversion"]]
                        })
                    }}
                />
                {/* <UpdateFiturModal
                    version={dataDashboardVersion}
                    showModal={this.state.updateFiturModal}
                    modalText={this.state.updateFiturText}
                    setState={(val, callback) => this.setState(val, callback)}
                    handleClose={() => {this.setState({updateFiturModal: false})}}
                    handlerCheckBoxChanges={() => {
                        let dashVs = localStorage.getItem("dashVs")
                        if(dashVs) {
                            if(parseFloat(dashVs) >= parseFloat(dataVersion["dashboardversion"])) {
                                localStorage.removeItem("dashVs");
                            }else {
                                localStorage.setItem("dashVs", dataVersion["dashboardversion"])
                            }
                        }else {
                            localStorage.setItem("dashVs", dataVersion["dashboardversion"])
                        }
                    }}
                /> */}
                <Tour steps={
                    steps
                }
                    isOpen={
                        this.state.guide === true
                    }
                    onAfterOpen={
                        this.disableBody
                    }
                    onBeforeClose={
                        this.enableBody
                    }
                    onRequestClose={
                        () => this.setState({
                            guide: false
                        })
                    }
                />
                {dashboard}

            </Fragment>
        )
    }
}

const mapStateToProps = state =>
({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});
const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps,
    mapDispatchToProps)(Dashboard);
