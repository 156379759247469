import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import { Spin } from "antd";
import ReactPaginate from 'react-paginate';
import Progres from './progres';
import RefreshIcon from '../../assets/icons/refresh.svg';
import PlusWhiteIcon from '../../assets/icons/plus-white.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import SearchIcon from '../../assets/icons/search.svg';
import PencilIcon from '../../assets/icons/pencil.svg';
import TrashIcon from '../../assets/icons/trash.svg';
import EmptyDataImage from '../../assets/images/data-kosong.png';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.css';
import i18n from "i18next";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import JamKerjaDivisi from './jam-kerja-divisi.js';
import JabatanDivisi from './jabatan-divisi';
import Swal from 'sweetalert2';

class DivisiGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_nama: "",
            paginate: 20,
            totalData: 0,
            totalPerpage: 0,
            pageCount: 0,
            perPage: 0,
            divisi_id: 0,
            data: [],
            progresModal: false,
            modalAdd: false,
            modalEdit: false,
            activeTab: 0,
            dataJamkerja: [],
            dataJabatan: [],
            id_jamkerja: [],
            nama_jamkerja: [],
            id_jabatan: [],
            shift_nama: "",
            checked_all: false,
            shift: [],
            lokasi: [],
            divisi: [],
            karyawan: [],
            divisi_name: "",
            divisi_payroll_type: "",
            disable_add: false,
            disable_edit: false,
            loadingList: false,
            loadingJamkerja: false,
            loadingJabatan: false
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    }

    loadData() {
        this.setState({
            loadingList: true
        })
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 20,
            page: this.state.currentPage + 1,
        }
        this.props.request("divisi/list-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        loadingList: false,
                        data: response.data.data,
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            })
    }
    loadJamkerja() {
        this.setState({
            loadingJamkerja: true
        })
        let form = {
            perusahaan_id: this.props.user.perusahaan_id,
            shift_nama: this.state.shift_nama,
        }
        this.props.request("jamKerja/list-jamkerja-guide-v2", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        dataJamkerja: response.data,
                        loadingJamkerja: false,
                        // modalAdd: true
                    })
                }
            })
    }
    loadJabatan() {
        this.setState({
            loadingJabatan: true
        })
        let form = {}
        this.props.request("divisi/list-divisi-jabatan-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        dataJabatan: response.data,
                        loadingJabatan: false
                    })
                }
            })
    }
    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift: response.shift,
                        lokasi: response.lokasi,
                        divisi: response.divisi,
                        karyawan: response.karyawan
                    }, () => {
                        if (this.state.shift.length !== 0 && this.state.lokasi.length !== 0 && this.state.divisi.length !== 0 && this.state.karyawan.length !== 0 && this.props.user.perusahaan.perusahaan_nama !== "") {
                            this.setState({
                                progresModal: true
                            })
                        }
                    })
                }
            })
    }
    addDivisi() {
        this.setState({
            disable_add: true
        })
        let form = {
            divisi_name: this.state.divisi_name,
            divisi_payroll_type: this.state.divisi_payroll_type,
            shift: this.state.id_jamkerja,
            position: this.state.id_jabatan
        }
        this.props.request("divisi/add-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                id_jabatan: [],
                                id_jamkerja: [],
                                divisi_payroll_type: "",
                                divisi_name: "",
                                modalAdd: false,
                                activeTab: 0,
                                disable_add: false
                            }, () => {
                                this.loadCheck()
                                this.setState({
                                    progresModal: true
                                })
                            })
                            this.loadData()
                            this.props.set_auth(this.props.api_token, response.perusahaan);
                        } else {
                            this.setState({
                                id_jabatan: [],
                                id_jamkerja: [],
                                divisi_payroll_type: "",
                                divisi_name: "",
                                modalAdd: false,
                                activeTab: 0
                            }, () => {
                                this.loadCheck()
                                this.setState({
                                    progresModal: true
                                })
                            })
                            this.loadData()
                            this.props.set_auth(this.props.api_token, response.perusahaan);
                        }
                    })
                } else {
                    this.setState({
                        disable_add: false
                    })
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }
    deleteDivisi() {
        let form = {
            divisi_id: this.state.divisi_id
        }
        this.props.request("divisi/delete-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    Swal.fire({
                        title: "Berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.loadData()
                            this.loadCheck()
                        } else {
                            this.loadData()
                            this.loadCheck()
                        }
                    })
                } else {
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }
    byIdDivisi() {
        let form = {
            divisi_id: this.state.divisi_id
        }
        this.props.request("divisi/by-id-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modalEdit: true,
                        divisi_name: response.data.divisi_name,
                        divisi_payroll_type: response.data.divisi_payroll_type,
                        id_jamkerja: response.id_shift,
                        id_jabatan: response.id_jabatan
                    })
                }
            })
    }
    editDivisi() {
        this.setState({
            disable_edit: true
        })
        let form = {
            divisi_id: this.state.divisi_id,
            divisi_name: this.state.divisi_name,
            divisi_payroll_type: this.state.divisi_payroll_type,
            shift_id: this.state.id_jamkerja,
            jabatan_id: this.state.id_jabatan
        }
        this.props.request("divisi/edit-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalEdit: false,
                                id_jabatan: [],
                                id_jamkerja: [],
                                divisi_payroll_type: "",
                                divisi_name: "",
                                modalAdd: false,
                                activeTab: 0,
                                disable_edit: false
                            })
                            this.loadData()
                        } else {
                            this.setState({
                                modalEdit: false,
                                id_jabatan: [],
                                id_jamkerja: [],
                                divisi_payroll_type: "",
                                divisi_name: "",
                                modalAdd: false,
                                activeTab: 0
                            })
                            this.loadData()
                        }
                    })
                } else {
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#143B5E",
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: 700
            },
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#143B5E",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            }
        }
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Daftar Divisi - iPresens</title>
                    <meta name="description" content="Daftar Jam Kerja" />
                </Helmet>
                <Progres
                    request={this.props.request}
                    loadCheck={() => this.loadCheck()}
                    shift={this.state.shift}
                    lokasi={this.state.lokasi}
                    divisi={this.state.divisi}
                    karyawan={this.state.karyawan}
                    progresModal={this.state.progresModal}
                    setState={(name, val) => this.setState(name, val)}
                    history={this.props.history}
                />
                <Modal size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalAdd} toggle={() => this.setState({
                    modalAdd: false, id_jamkerja: [],
                    activeTab: 0,
                    id_jabatan: [],
                    divisi_name: "",
                    divisi_payroll_type: "",
                })}>
                    <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                        modalAdd: false,
                        id_jamkerja: [],
                        activeTab: 0,
                        id_jabatan: [],
                        divisi_name: "",
                        divisi_payroll_type: "",
                    })}>
                        <div style={style_add.title_header}>{i18n.t('tambahdivisi')}</div>
                    </ModalHeader>
                    <ModalBody className='p-0' style={{ backgroundColor: "#F9F9F9" }}>
                        <div className='row pt-4 px-4 pb-3 mx-0 mb-2' style={{ backgroundColor: "#FFFFFF" }}>
                            <div className='col-md-4 pl-0'>
                                <label className='form-label' style={style_add.label_input}>{i18n.t('namadivisi')}</label>
                                <input className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.divisi_name} placeholder='Ketik nama divisi disini...' name="divisi_name" style={style_add.input} />
                            </div>
                            <div className='col-md-4 pl-0'>
                                <label className='form-label' style={style_add.label_input}>{i18n.t('metodehitunggaji')}</label>
                                <select className='form-control' onChange={(e) => this.handleChange(e)} name="divisi_payroll_type" value={this.state.divisi_payroll_type} style={style_add.select}>
                                    <option value="">{i18n.t('pilihmetode')}</option>
                                    <option value="jam">{i18n.t('jam')}</option>
                                    <option value="hari">{i18n.t('hari')}</option>
                                    <option value="bulan">{i18n.t('bulan')}</option>
                                </select>
                            </div>
                            <div className='col-md-4 pr-0'>
                            </div>
                        </div>
                        <div className='row pt-3 px-4 pb-4 mx-0' style={{ backgroundColor: "#FFFFFF" }}>
                            <div className='col-md-12 px-0'>
                                <Tabs selectedIndex={this.state.activeTab} onSelect={(e) => this.setState({ activeTab: e })}>
                                    <TabList className="nav nav-tabs tabs-coupon">
                                        <Tab className="nav-link">Jam Kerja</Tab>
                                        <Tab className="nav-link">Jabatan</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <JamKerjaDivisi
                                            request={this.props.request}
                                            match={this.props.match}
                                            history={this.props.history}
                                            user={this.props.user}
                                            modalAdd={this.state.modalAdd}
                                            dataJamkerja={this.state.dataJamkerja}
                                            setState={(state, value) => this.setState(state, value)}
                                            checked_all={this.state.checked_all}
                                            id_jamkerja={this.state.id_jamkerja}
                                            loadingJamkerja={this.state.loadingJamkerja}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <JabatanDivisi
                                            request={this.props.request}
                                            match={this.props.match}
                                            history={this.props.history}
                                            dataJabatan={this.state.dataJabatan}
                                            user={this.props.user}
                                            id_jabatan={this.state.id_jabatan}
                                            setState={(state, value) => this.setState(state, value)}
                                            loadingJabatan={this.state.loadingJabatan}
                                        />
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn ipresens-btn-cancel text-capitalize' onClick={() => {
                            this.setState({
                                modalAdd: false,
                                id_jamkerja: [],
                                activeTab: 0,
                                id_jabatan: [],
                                divisi_name: "",
                                divisi_payroll_type: "",
                                checked_all: false
                            })
                        }}>{i18n.t("batal")}</button>
                        <button className='btn ipresens-btn-color text-capitalize' disabled={this.state.disable_add} onClick={() => this.addDivisi()}>{i18n.t("simpan")}</button>
                    </ModalFooter>
                </Modal>
                <Modal size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalEdit} toggle={() => this.setState({
                    modalEdit: false, id_jamkerja: [],
                    activeTab: 0,
                    id_jabatan: [],
                    divisi_name: "",
                    divisi_payroll_type: "",
                    checked_all: false
                })}>
                    <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                        modalEdit: false,
                        id_jamkerja: [],
                        activeTab: 0,
                        id_jabatan: [],
                        divisi_name: "",
                        divisi_payroll_type: "",
                        checked_all: false
                    })}>
                        <div style={style_add.title_header}>Edit Divisi</div>
                    </ModalHeader>
                    <ModalBody className='p-0' style={{ backgroundColor: "#F9F9F9" }}>
                        <div className='row pt-4 px-4 pb-3 mx-0 mb-2' style={{ backgroundColor: "#FFFFFF" }}>
                            <div className='col-md-4 pl-0'>
                                <label className='form-label' style={style_add.label_input}>Nama Divisi</label>
                                <input className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.divisi_name} placeholder='Ketik nama divisi disini...' name="divisi_name" style={style_add.input} />
                            </div>
                            <div className='col-md-4 pl-0'>
                                <label className='form-label' style={style_add.label_input}>Metode Perhitungan Gaji</label>
                                <select className='form-control' onChange={(e) => this.handleChange(e)} name="divisi_payroll_type" value={this.state.divisi_payroll_type} style={style_add.select}>
                                    <option value="">Pilih Metode</option>
                                    <option value="jam">Jam</option>
                                    <option value="hari">Hari</option>
                                    <option value="bulan">Bulan</option>
                                </select>
                            </div>
                            <div className='col-md-4 pr-0'>
                            </div>
                        </div>
                        <div className='row pt-3 px-4 pb-4 mx-0' style={{ backgroundColor: "#FFFFFF" }}>
                            <div className='col-md-12 px-0'>
                                <Tabs selectedIndex={this.state.activeTab} onSelect={(e) => this.setState({ activeTab: e })}>
                                    <TabList className="nav nav-tabs tabs-coupon">
                                        <Tab className="nav-link">Jam Kerja</Tab>
                                        <Tab className="nav-link">Jabatan</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <JamKerjaDivisi
                                            request={this.props.request}
                                            match={this.props.match}
                                            history={this.props.history}
                                            user={this.props.user}
                                            dataJamkerja={this.state.dataJamkerja}
                                            modalAdd={this.state.modalAdd}
                                            setState={(state, value) => this.setState(state, value)}
                                            checked_all={this.state.checked_all}
                                            id_jamkerja={this.state.id_jamkerja}
                                            loadingJamkerja={this.state.loadingJamkerja}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <JabatanDivisi
                                            request={this.props.request}
                                            match={this.props.match}
                                            history={this.props.history}
                                            dataJabatan={this.state.dataJabatan}
                                            user={this.props.user}
                                            id_jabatan={this.state.id_jabatan}
                                            setState={(state, value) => this.setState(state, value)}
                                            loadingJabatan={this.state.loadingJabatan}
                                        />
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn text-capitalize ipresens-btn-cancel' onClick={() => {
                            this.setState({
                                modalEdit: false,
                                id_jamkerja: [],
                                activeTab: 0,
                                id_jabatan: [],
                                divisi_name: "",
                                divisi_payroll_type: "",
                                checked_all: false
                            })
                        }}>Batalkan</button>
                        <button className='btn ipresens-btn-color text-capitalize' disabled={this.state.disable_edit} onClick={() => this.editDivisi()}>Simpan</button>
                    </ModalFooter>
                </Modal>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12 px-0'>
                            <div className='card border'>
                                <div className='card-header border-bottom' style={style.card_header}>Divisi</div>
                                <div className='card-body p-4'>
                                    <div className='row mb-3'>
                                        <div className="col-md-6 mb-2">
                                            <div className='d-flex flex-row'>
                                                <div className='flex-fill pr-2'>
                                                    <div className='input-group'>
                                                        <div className='input-group-prepend'>
                                                            <div className='input-group-text border-right-0' style={{ backgroundColor: "#F9F9F9" }}>
                                                                <img alt='' src={SearchIcon} />
                                                            </div>
                                                        </div>
                                                        <input type='text' className='form-control border-left-0' onChange={(e) => this.handleChange(e)} name='filter_nama' placeholder={i18n.t('carinamadivisi')+'...'} style={style.filter_nama} value={this.state.filter_nama} onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.loadData()
                                                            }
                                                        }} ></input>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='btn ipresens-btn-reset-filter' style={style.button_refresh}><img alt="" src={RefreshIcon} onClick={() => {
                                                        this.setState({
                                                            filter_nama: ""
                                                        }, () => this.loadData())
                                                    }} /></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <button onClick={() => {
                                                this.loadJamkerja()
                                                this.loadJabatan()
                                                this.setState({
                                                    modalAdd: true
                                                })
                                            }} className='btn ipresens-btn-color text-capitalize pull-right button-w-full'><img className='mr-2' alt='' src={PlusWhiteIcon} />{i18n.t('tambahdivisi')}</button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='table-responsive'>
                                                <table className='table table-striped table-borderless'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "30px"
                                                            }}>#</th>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "50px",
                                                                maxWidth: "447px"
                                                            }}>{i18n.t('nama')}</th>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "180px"
                                                            }}>{i18n.t('jumlahkaryawan')}</th>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "250px"
                                                            }}>{i18n.t('metodehitunggaji')}</th>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "100px"
                                                            }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.loadingList ?
                                                            <tr>
                                                                <td colSpan={5} style={{ textAlign: "center" }}>
                                                                    <Spin size="large" />
                                                                </td>
                                                            </tr>
                                                            :
                                                            this.state.data.length === 0 ?
                                                                <tr>
                                                                    <td colSpan={5} style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}>
                                                                        <img src={EmptyDataImage} className="ipresens-image-empty" alt="icon data kosong" />
                                                                        <p className='mb-2'>{i18n.t("datakosong")}...</p>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                this.state.data.map((item, index) => {
                                                                    no += 1;
                                                                    return (
                                                                        <tr>
                                                                            <td style={style.td_style}>{no}</td>
                                                                            <td style={style.td_style}>
                                                                                <div className='row m-0' style={{
                                                                                    fontWeight: 700,
                                                                                    fontSize: "14px",
                                                                                    color: "#252B2B",
                                                                                    lineHeight: "24px"
                                                                                }}>{item.divisi_name}</div>
                                                                                <div className='row m-0'>
                                                                                    <div style={{
                                                                                        fontWeight: 400,
                                                                                        fontSize: "12px",
                                                                                        color: "#252B2B",
                                                                                        lineHeight: "16px"
                                                                                    }}>Jam Kerja berlaku:</div>&nbsp;<p style={{
                                                                                        fontWeight: 400,
                                                                                        fontSize: "12px",
                                                                                        color: "#5D6F80",
                                                                                        lineHeight: "16px"
                                                                                    }}>{item.data_shift_name.toString().replaceAll(",", ", ")}</p></div>
                                                                            </td>
                                                                            <td style={style.td_style}>{item.karyawan_many.length} karyawan</td>
                                                                            <td style={style.td_style} className='text-capitalize'>{item.divisi_payroll_type}</td>
                                                                            <td style={style.td_style}>
                                                                                <button className='hover-pointer' style={{
                                                                                    backgroundColor: "transparent",
                                                                                    border: "0px"
                                                                                }} onClick={() => {
                                                                                    this.setState({
                                                                                        divisi_id: item.divisi_id
                                                                                    }, () => {
                                                                                        this.byIdDivisi()
                                                                                        this.loadJabatan()
                                                                                        this.loadJamkerja()
                                                                                    })
                                                                                }}>
                                                                                    <img alt='' src={PencilIcon} />
                                                                                </button>
                                                                                <button className='hover-pointer' style={{
                                                                                    backgroundColor: "transparent",
                                                                                    border: "0px"
                                                                                }} onClick={() => {
                                                                                    Swal.fire({
                                                                                        title: 'Apakah anda yakin?',
                                                                                        text: "Anda tidak dapat mengembalikan data ini setelah dihapus!",
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonColor: '#3085d6',
                                                                                        cancelButtonColor: '#d33',
                                                                                        confirmButtonText: 'Hapus',
                                                                                        cancelButtonText: "Batal"
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            this.setState({
                                                                                                divisi_id: item.divisi_id
                                                                                            }, () => this.deleteDivisi())
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                                    <img alt='' src={TrashIcon} />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer p-0'>
                                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                        {/* {this.state.data.length !== 0 && */}
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}</div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeft} alt='' />}
                                                    nextLabel={<img src={ArrowRight} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                                    activeClassName={'active custom-active'}
                                                />
                                                {/* catatan.
                                                custom classname pagination dipanggil dari file css di folder absensi harian
                                                ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini */}
                                            </div>
                                        </>
                                        {/* // } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DivisiGuide);
